<template>
  <div class="rounded-lg flex items-center justify-center my-5">
    <div class="w-11/12 md:w-6/12 p-6 bg-white bg-opacity-80 shadow-xl">
      <h1 class="font-bold text-lg  text-green-1002 text-center bold">
        Obrigado
      </h1>
      <p class="text-sm text-justify px-2 mt-2">
        A sua inscrição será cancelada para recebimento de e-mails
      </p>
      <p class="text-sm text-justify px-2 mt-2">
        Para se inscrever novamente acesse o painel do usuário, ou entre em
        contato com o seu sindicato.
      </p>
      <form>
        <button
          @click.prevent="unsubscribe"
          class="py-1 bg-green-1002 text-white text-center w-full mt-3 mb-3 text-lg focus:outline-none"
        >
          DESCADASTRAR
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios';

export default {
  name: 'Unsubscribe',

  title() {
    return `${process.env.VUE_APP_NAME} | Cancelar Assinatura de E-mail`;
  },

  methods: {
    unsubscribe() {
      axios
        .post(
          `/api/subscription/unsubscribe-email/${this.$route.params.idUser}`
        )
        .then(({ data }) => {
          this.$toast.success(data.data.message);
        })
        .then(() => {
          this.$router.push({ name: 'login' });
        });
    }
  }
};
</script>
