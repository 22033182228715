<template>
  <app-layout>
    <div class="flex w-full">
      <div class="rounded-lg flex w-full items-center justify-center my-5">
        <loader-animation :height="'h-96'" v-if="isLoading" />
        <div v-else-if="!error" class="flex flex-col w-11/12 md:w-6/12 p-6 bg-white bg-opacity-80 shadow-2xl">
          <h1 class="font-bold text-lg  text-green-1002 text-center bold">
            Obrigado por participar da Colheita de Talentos
          </h1>
          <p class="text-base text-justify px-2 mt-2">
            Seu cadastro foi ativado com sucesso.
          </p>
          <p class="text-base text-justify px-2 mt-2">
            Clique no botão abaixo e você será redirecionado para a página de login.
          </p>
          <p class="text-base text-justify px-2 mt-2">
            Utilize a senha que você definiu no momento do cadastro para efetuar seu login.
          </p>
          <router-link
            class="bg-green-1002 text-white text-center w-full mt-10 mb-3 p-2 rounded-md text-lg focus:outline-none"
            :to="{ name: 'login' }">
            Ir para a pagina de Login
          </router-link>
        </div>
        <div v-else class="flex flex-col w-11/12 md:w-6/12 p-6 bg-white bg-opacity-80 shadow-2xl">
          <h1 class="font-bold text-lg  text-green-1002 text-center bold">
            Ops.. Houve um problema com a sua solicitação.
          </h1>
          <p class="text-base text-justify px-2 mt-2">
            Não foi possivel encontrar o seu acesso para realizar a ativação, tente clicar novamente no link que enviamos
            para o seu e-mail.
          </p>
          <p class="text-base text-justify px-2 mt-2">
            Se o problema persistir, entre em contato com o Sindicato Rural da sua Região.
          </p>
          <a
          class="bg-green-1002 text-white text-center w-full mt-10 mb-3 p-2 rounded-md text-lg focus:outline-none"
          href="https://sistemafamato.org.br/sindicatos-rurais/encontre-seu-sindicato-rural/"
          target="_blank"
          >Encontrar meu Sindicato Rural</a
        >
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../components/LoaderAnimation';
// import { create } from 'domain';

export default {
  name: 'activate',

  title() {
    return `${process.env.VUE_APP_NAME} | Ativar cadastro`;
  },

  components: {
    AppLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: true,
      error: false
    };
  },

  methods: {
    activate() {
      axios
        .put(`/api/associados/activation/${this.$route.params.idUser}`)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
        });
    }
  },

  created() {
    this.activate();
  }
};
</script>
